<template>
    <div>
        <RedefinirSenha />
    </div>
</template>

<script>
import RedefinirSenha from "../components/redefinirSenha/RedefinirSenha.vue";
export default {
    components: {
        RedefinirSenha
    }
};
</script>

<style lang="scss"></style>
