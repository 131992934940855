<template>
    <div class="nova-senha-container">
        <unicom-linha>
            <unicom-coluna class="container-imagem" md="6" lg="7" v-if="tamanhoJanela > 425">
                <figure>
                    <img src="../../../public/assets/thumbnail_2-01.jpg" />
                    <div class="texto-giu">
                        <div class="fonte-primaria negrito textoLogin">
                            <p>Gestão</p>
                            <p>Institucional</p>
                            <p>Unimed</p>
                        </div>
                    </div>
                </figure>
            </unicom-coluna>

            <unicom-coluna class="container-nova-senha" md="6" lg="5">
                <div class="nova-senha">
                    <ValidationObserver ref="redefinirSenha" v-if="!sucesso">
                        <unicom-linha espacamento>
                            <unicom-coluna class="fonte-secundaria subtitulo linha-icone">
                                <div class="icone-container" @click="voltar()">
                                    <unicom-icone :nome="'arrow-left'" :tamanho="22" />
                                </div>
                                <div class="label-container" @click="voltar()">
                                    <p>Cancelar</p>
                                </div>
                            </unicom-coluna>
                        </unicom-linha>
                        <h2 class="fonte-primaria negrito">{{ titulo }}</h2>
                        <unicom-linha espacamento class="linha-login">
                            <unicom-coluna>
                                <p class="fonte-secundaria texto">
                                    Para continuar, precisamos que crie uma nova senha.
                                </p>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha espacamento class="linha-login">
                            <unicom-coluna>
                                <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                    <unicom-campo-texto
                                        v-model="senha"
                                        :titulo="'Nova senha *'"
                                        :placeholder="'Insira sua nova senha'"
                                        :tipoCampo="password_type['nova']"
                                        :mensagemErro="errors[0]"
                                    />
                                    <unicom-icone
                                        class="icone"
                                        :nome="password_icon['nova']"
                                        :tamanho="22"
                                        @click="toggleTypePassword('nova')"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha espacamento class="linha-login">
                            <unicom-coluna>
                                <validation-provider
                                    :rules="`novaSenha: ${this.senha}`"
                                    mode="eager"
                                    v-slot="{ errors }"
                                >
                                    <unicom-campo-texto
                                        v-model="confirmaSenha"
                                        :titulo="'Repita a senha'"
                                        :placeholder="'Insira sua nova senha'"
                                        :tipoCampo="password_type['confirma']"
                                        :mensagemErro="errors[0]"
                                    />
                                    <unicom-icone
                                        class="icone"
                                        :nome="password_icon['confirma']"
                                        :tamanho="22"
                                        @click="toggleTypePassword('confirma')"
                                    /> 
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha espacamento class="linha-login">
                            <unicom-coluna>
                                <p class="fonte-secundaria texto">A nova senha deve conter:</p>
                                <div class="lista-validacao" v-for="(item, index) in listaValidacao" :key="index">
                                    <div :class="`icone-container ${validacaoConcluida(index)}`">
                                        <unicom-icone :nome="'check-circle'" :tamanho="14" />
                                    </div>
                                    <div :class="`fonte-secundaria texto label-container ${validacaoConcluida(index)}`">
                                        {{ item.descricao }}
                                    </div>
                                </div>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha espacamento class="linha-login">
                            <unicom-coluna>
                                <unicom-botao
                                    :tipo="'primario'"
                                    :largura="'300'"
                                    @click="continuar()"
                                    :desabilitado="liberarBotao"
                                >
                                    CRIAR NOVA SENHA
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </ValidationObserver>
                    <div v-else class="sucesso">
                        <h2 class="fonte-primaria negrito">Nova senha criada!</h2>
                        <unicom-linha espacamento="false" class="linha-checkbox">
                            <p class="fonte-secundaria texto">Agora você já pode entrar ;)</p>
                        </unicom-linha>
                        <unicom-linha espacamento="false" class="linha-login">
                            <unicom-coluna>
                                <unicom-botao :tipo="'primario'" bloco @click="voltar()">
                                    ENTRAR NO PORTAL
                                </unicom-botao>
                            </unicom-coluna>
                        </unicom-linha>
                    </div>
                </div>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import RedefinirSenhaServiceMixin from "../../mixins/services/redefinirSenha/RedefinirSenhaServiceMixin";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    mixins: [RedefinirSenhaServiceMixin],
    data() {
        return {
            sucesso: false,
            senha: "",
            confirmaSenha: "",
            usuario: "",
            otp: "",
            listaValidacao: [],

            tamanhoJanela: 0,

            password_icon: {
                nova: "eye",
                confirma: "eye"
            },

            password_type: {
                nova: "password",
                confirma: "password"
            }
        };
    },
    created() {
        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    beforeMount() {
        this.getListaValidacao();
        this.montaDadosRota();
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },
    methods: {
        montaDadosRota() {
            let rota = this.$route;
            this.usuario = rota.query.username;
            this.otp = rota.query.passwordrecoverotp;
        },
        getListaValidacao() {
            this.getRegrasValidacao().then(response => {
                this.listaValidacao = response.data;
            });
        },
        validacaoConcluida(index) {
            let regra = new RegExp(this.listaValidacao[index].regra.slice(1, -1));
            return regra.test(this.senha) ? "ativo" : "";
        },
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        voltar() {
            this.limparNovaSenha();
            this.$router.push("/login");
        },
        limparNovaSenha() {
            (this.senha = ""), (this.confirmaSenha = "");
            this.usuario = "";
            this.otp = "";
            this.listaValidacao = [];
        },
        async continuar() {
            let validacaoNovaSenha = await this.$refs.redefinirSenha.validate();
            if (validacaoNovaSenha) {
                this.novaSenha();
            }
        },
        novaSenha() {
            this.finalizarRecuperacaoSenha(this.montarNovaSenha()).then(() => {
                this.sucesso = true;
            });
        },
        montarNovaSenha() {
            return {
                username: this.usuario,
                otp: this.otp,
                newPassword: this.senha
            };
        },
        toggleTypePassword(campo) {
            if (this.toggle) {
                this.password_icon[campo] = "eye-off";
                this.password_type[campo] = "text";
            } else {
                this.password_icon[campo] = "eye";
                this.password_type[campo] = "password";
            }

            this.toggle = !this.toggle;
        }
    },
    computed: {
        titulo() {
            return !this.sucesso ? "Crie uma nova senha" : "Nova senha criada!";
        },
        liberarBotao() {
            let erro = false;
            this.listaValidacao.forEach(item => {
                let regra = new RegExp(item.regra.slice(1, -1));
                erro = !(regra.test(this.senha) && this.senha != "" && this.confirmaSenha != "");
            });
            return erro;
        }
    }
};
</script>

<style lang="scss" scoped>
.textoLogin {
    font-size: 58px;
    line-height: 58px;
    color: white;
}

.texto-giu {
    position: absolute;
    top: 32%;
    left: 40%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: left;
}

.nova-senha-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    width: 98vw;
    height: 100vh;

    box-sizing: border-box;
    position: fixed;

    .container-imagem {
        display: block;
        float: left;
        box-sizing: border-box;

        & img {
            width: 100vh;
            object-fit: fill;
        }
    }

    .container-nova-senha {
        display: block;
        box-sizing: border-box;
        float: left;
        height: 100vh;
        background-color: #fff;

        overflow: auto;

        .nova-senha {
            margin: 40px 232px 0px 106px;
            width: 340px;
            height: 580px;

            .linha-icone {
                color: #bebebe;
                height: 24px;
                margin-bottom: 12px;

                .icone-container {
                    display: block;
                    float: left;
                    width: 24px;
                    cursor: pointer;
                }

                .label-container {
                    display: block;
                    float: left;
                    width: calc(100% - 24px);

                    p {
                        color: #bebebe;
                        margin-top: 3px;
                        margin-left: 16px;
                        cursor: pointer;
                    }
                }
            }

            h2 {
                font-size: 32px;
                line-height: 55px;
                color: #00995d;
            }

            p {
                margin: 0;
                color: #60656c;
            }

            .linha-checkbox {
                margin-bottom: 24px;
            }

            .linha-login {
                margin-top: 24px;

                &.primeira-linha {
                    margin-top: 0;
                }

                .icone {
                    position: absolute;
                    top: 33px;
                    right: 25px;
                    cursor: pointer;
                    color: #707070;
                }

                .lista-validacao {
                    margin: 14px 0;
                    box-sizing: border-box;
                    height: 16px;
                    color: #60656c;

                    &:last-child {
                        margin: 0;
                    }

                    .icone-container {
                        float: left;
                        box-sizing: border-box;
                        width: 16px;
                        height: 16px;

                        &.ativo {
                            color: #00995d;
                        }
                    }

                    .label-container {
                        float: left;
                        margin-left: 6px;
                        box-sizing: border-box;
                        width: calc(100% - 22px);

                        &.ativo {
                            text-decoration: line-through;
                            color: #00995d;
                        }
                    }
                }
            }

            small {
                margin-left: 16px;
                color: #1492e6;
                cursor: pointer;
            }
        }

        .sucesso {
            h2 {
                font-size: 32px;
                line-height: 55px;
                color: #00995d;
                padding-left: 14px;
            }

            p {
                margin: 0;
                color: #60656c;
                padding-left: 14px;
            }
        }
    }
}
</style>
